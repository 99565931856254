<template>
  <div class="imex__available-exports-dropdown mr-1">
    <b-dropdown
      :id="id"
      :disabled="!permitted"
      :text="$t('Recently exported data')"
      variant="outline-primary"
      @shown="$emit('open')"
    >
      <b-dropdown-item
        v-for="(item, i) in list"
        :key="i"
        @click="download(item)"
      >
        <feather-icon
          icon="FileTextIcon"
          class="mr-1"
        />
        {{ item.fileName }}
      </b-dropdown-item>
    </b-dropdown>
    <b-tooltip
      v-if="!permitted"
      :target="id"
    >
      {{ $t('no-view-exports-imex-permision') }}
    </b-tooltip>
  </div>
</template>

<script>
import { downloadFromURL } from '@/helpers/app'
import imexService from '@/services/imex'

export default {
  props: {
    list: {
      type: Array,
      required: true,
    },

    id: {
      type: String,
      default: 'view-recently-exported-files',
    },

    permitted: {
      type: Boolean,
      default: true,
    },

    getExportFile: {
      type: Function,
      default: null,
    },
  },

  methods: {
    async download(file) {
      const { response } = await this.$async(this.getExportFile ? this.getExportFile({ fileId: file.id }) : imexService.getOneExportFile(file.id))

      downloadFromURL(response?.data?.url, file.fileName)
    },
  },
}
</script>
<style lang="scss">
.imex__available-exports-dropdown {
  ul.dropdown-menu {
    min-width: 100%;
  }
}
</style>
